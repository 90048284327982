<template>
  <div class="institution">
<!--    <section class="search-option">-->
<!--      <div class="type">-->
<!--        <ul>-->
<!--          <li  :class="[{'active': index1 == 0 }]" @click="select(1,0)">商标</li>-->
<!--          <li :class="[{'active': index1 == 1 }]" @click="select(1,1)">专利</li>-->
<!--        </ul>-->
<!--      </div>-->

<!--      <div class="area">-->
<!--        <span>地区:</span>-->
<!--        <ul>-->
<!--          <li :style="{color: item.disabled ? '#c0c4cc' : '',background: item.disabled ? '#f5f7fa' : '', cursor: item.disabled ? 'no-drop' : ''}" :disabled="item.disabled" :class="[{'active': index2 == index }]" @click="select(2,index,item.disabled)" v-for="(item,index) in regionList" :key="index">{{item.label}}</li>-->
<!--        </ul>-->
<!--      </div>-->

<!--      <div class="city">-->
<!--        <span></span>-->
<!--        <ul>-->
<!--          <li style="width: 68px;height: 32px;padding: 0" :class="[{'active': index3 == 0 }]" @click="select(3,0)">全部</li>-->
<!--          <li :class="[{'active': index3 == index + 1 }]" @click="select(3,index + 1, item)" v-for="(item,index) in cityArr" :key="index">{{item.county || item.city}}</li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </section>-->
    <div class="monitoring_table">
      <div class="type">
        <ul class="type_switch">
          <li :class="[{'active': index1 == 0 }]" @click="select(1,0)" >商标</li>
          <li :class="[{'active': index1 == 1 }]" @click="select(1,1)">专利</li>
        </ul>
        <div class="type_select">
          <el-select  v-model="value1"  @change="e => select(2,e)" style="margin-right: 27px" placeholder="请选择">
            <el-option
              v-for="item in regionList"
              :disabled="item.disabled"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
          <el-select clearable  v-model="value2" @change="e => select(3, cityArr.filter(item => item.county == e || item.city == e)[0])"  placeholder="请选择">
            <el-option
              v-for="item in cityArr"
              :key="item.label"
              :label="item.county || item.city"
              :value="item.county || item.city">
            </el-option>
          </el-select>
        </div>
      </div>
      <vxe-grid
        stripe
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="665px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        v-bind="index1 == 0 ? gridOption : gridOptions"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >
        <template #pager>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 15, 20, 50, 100]"
            layout="total, sizes, prev, pager, next"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total">
          </el-pagination>
        </template>
        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
    </div>
  </div>
</template>

<script>
  import classify from "@/components/query/public/classInTheNiceClassification";
  import login from "@/components/common/login"
  import moment from 'moment'
  import {customStart} from "@/request/trademarkPayment";

  export default {
    components: { classify, login },
    data() {
      return {
        value1: '北京',
        value2: '',
        //filterChangeObj
        filterChangeObj: {
          agentName: '',
          agentNo: '',
          agentAddress: '',
          orderField: '',
          orderType: ''
        },
        cityArr: [],
        regionList: [
          {
            label: '北京',
            disabled: false,
          },
          {
            label: '天津',
            disabled: false,
          },
          {
            label: '河北',
            disabled: false,
          },          {
            label: '山西',
            disabled: false,
          },
          {
            label: '内蒙古',
            disabled: false,
          },
          {
            label: '辽宁',
            disabled: false,
          },
          {
            label: '吉林',
            disabled: false,
          },
          {
            label: '黑龙江',
            disabled: false,
          },
          {
            label: '上海',
            disabled: false,
          },
          {
            label: '江苏',
            disabled: false,
          },          {
            label: '浙江',
            disabled: false,
          },
          {
            label: '安徽',
            disabled: false,
          },
          {
            label: '福建',
            disabled: false,
          },
          {
            label: '江西',
            disabled: false,
          },
          {
            label: '山东',
            disabled: false,
          },
          {
            label: '河南',
            disabled: false,
          },
          {
            label: '湖北',
            disabled: false,
          },
          {
            label: '湖南',
            disabled: false,
          },
          {
            label: '广东',
            disabled: false,
          },
          {
            label: '广西',
            disabled: false,
          },
          {
            label: '海南',
            disabled: false,
          },          {
            label: '重庆',
            disabled: false,
          },
          {
            label: '四川',
            disabled: false,
          },
          {
            label: '贵州',
            disabled: false,
          },
          {
            label: '云南',
            disabled: false,
          },
          {
            label: '西藏',
            disabled: false,
          },          {
            label: '陕西',
            disabled: false,
          },          {
            label: '甘肃',
            disabled: false,
          },
          {
            label: '青海',
            disabled: false,
          },
          {
            label: '宁夏',
            disabled: false,
          },
          {
            label: '新疆',
            disabled: false,
          },          {
            label: '香港',
            disabled: true,
          },
          {
            label: '澳门',
            disabled: true,
          },
          {
            label: '台湾',
            disabled: true,
          }
        ],
        index1: 0,
        index2: 0,
        index3: 0,
        monitorType:[
          {
            label: '近似监控',
            value: 1
          },
          {
            label: '竞争对手监控',
            value: 2
          }
        ],
        //监控商标
        monitoringTrademark: "",
        //选择时间
        start_date_1: '',
        start_date_2: '',
        //结束日期
        end_date_1: '',
        end_date_2: '',
        //分页信息
        current: 1,
        size: 20,
        total: 0,
        //表格过滤使用
        isImage: '',
        appnos: '',
        countryNames: '',
        intCls: '',
        cnApplicant: '',
        //表格展示配置
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              align: 'left',
              field: 'agentCode',
              title: '编号',
              width: '100',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'agentName',
              title: '代理机构名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'address',
              title: '代理机构地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'phone',
              title: '联系电话'
            },
            {
              align: 'left',
              field: 'director',
              title: '负责人'
            },
            {
              align: 'left',
              field: 'agentNum',
              title: '专利代理从业人数'
            },
            {
              align: 'left',
              sortable: true,
              sortType: 'string',
              field: 'paCountLastyear',
              title: '去年办案量'
            },
            {
              align: 'left',
              field: 'invensionCountLastyear',
              title: '去年发明办案量'
            },
            {
              align: 'left',
              field: 'utilityCountLastyear',
              title: '去年实用新型办案量'
            }
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //表格展示配置
        gridOption: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              align: 'left',
              field: 'agentCode',
              title: '编号',
              width: '100',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'agentName',
              title: '代理机构名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'address',
              title: '代理机构地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'phone',
              title: '联系电话',
            },
            {
              align: 'left',
              field: 'director',
              title: '负责人',
            },
            {
              align: 'left',
              field: 'agentNum',
              title: '商标代理从业人数',
            },
            {
              align: 'left',
              sortable: true,
              sortType: 'string',
              field: 'tmCountLastyear',
              title: '去年办案量',
              width: '120'
            }
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //总次数
        totalTimes: '',
        //使用次数
        usageTimes: '',
        //剩余次数
        remainTimes: '',
        //新增监控dialog
        monitor: false,
        //新增监控或修改监控 0-新增 1-修改
        addOrEdit:0,
        addOrEditData:{
          id:"",
          countrySet:[],
          niceSet:"",
          trademarkName:"",
          wordImageUrl:"",
          iconImageUrl:"",
          remarks:"",
          monitorType:1,
          sourctType:0,
          revokeThreeFlag:undefined,
          renewalFlag:undefined,
          trademarkApplyNo:undefined,
          trademarkOwnFlag:undefined,
          renewalNiceCode:undefined,
          applyPersonName:undefined
        },
        containCN:false,
        beforeEditCountryListNum:0,
        //申请人联想
        applyPersonNameArr:[],
        //国家筛选
        filterText: '',
        //国家树形表配置
        defaultProps: {
          children: 'children',
          label: 'label',
          id: 'code'
        },
        //上传图片header
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        //是否禁止新增监控(防止多次提交使用)
        isDisabled: false,
        //类别选择浮窗
        niceVisible: false,
        //商标监控种类选择-0 商标续展种类选择-1
        monitorCategoryOrRenewalCategory:0,
        //登录浮窗
        dialogVisibles: false,
        dialogClose: false,
        current: 1,
        size: 20,
        obj:{}
      }
    },

    async mounted() {
      this.isDisabled = false
      this.getArea()
      this.getAreData()
    },

    watch: {
      monitor(newVal,oldVal){
        if(newVal){
          this.applyPersonNameArr = []
        }
      }
    },

    methods: {
      select(type, item){
        switch (type) {
          case 1 : this.index1 = item; this.gridOption.data = []; this.gridOptions.data = []; break;
          case 2 : this.obj = {};this.value2='';this.getArea(); break;
          case 3 : if(item) {this.obj = item ;this.getAreData(item)}else {this.obj = {} ;this.getAreData(item)}; break;
        }
      },
      // 获取地区
      getArea(){
        this.$axios.post("/custody/agent/queryArea",  {
          province: this.value1,
          city: ''
        })
          .then(({data}) => {
            this.cityArr = data
          })
          .catch( error => {console.log(error) })
      },
      // 获取地区
      getAreData(){
        this.$axios.post("/custody/agent/queryAgent",  {
          agentType:	this.index1 == 0 ? 2 : 1,
          agentName:	'',
          province:	this.value1,
          city:	!this.value1  ? undefined :  this.obj.city == '两江新区' ?  undefined : this.obj.city,
          district:	!this.value1 ? undefined : this.obj.city == '两江新区' ?  this.obj.city : this.obj.county,
          current: this.current,
          size: this.size,
          ...this.filterChangeObj
        })
          .then(({data}) => {
            this.gridOptions.data = data.records
            this.gridOption.data = data.records
            this.total = data.total
          })
          .catch( error => {console.log(error) })
      },
      onTypeBlur(e) {
        if (e.target.value) {
          // this.type = e.target.value;
          // 如果是对象，要使用this.$set方法
          this.$set('addOrEditData', 'applyPersonName', e.target.value)
        }
      },
      uploadChangeFn(file, fileList) {
        let imgSize = Number(file.size / 1024 / 1024);

        if (imgSize > 3) {
          this.$message('文件大小不能超过3MB，请重新上传');
          this.materialList[scope.$index].fileList.splice(scope.index, 1);
          return;
        }
      },
      optionEvent(val){
        this.addOrEditData.applyPersonName = val
      },
      remotePeopleMethod(val){
        this.addOrEditData.applyPersonName = val
        if(this.addOrEditData.countrySet.length > 0){
          this.applicantHit(val)
        }else {
          this.$message('为了为您提供更准确地结果，请您先选择国家/地区');
        }
      },
      //根据国家联想申请人名称
      applicantHit(val){
        let arr = []
        this.addOrEditData.countrySet.map(item => {
          arr.push(this.searchCountryCodeByName(item))
        })
        this.$axios
          .post("/common/query/applicantHit",{
            country:arr.join(';'),
            applicant: val
          }).then(({data}) => {
          this.applyPersonNameArr = data
          return;
        })
      },
      handleCheckChange(){
        this.confirm()
      },
      //判断用户是否登录状态
      async init() {
        //若已经登录，则获取监控列表
        if (this.tool.getCookie("auth")) {
          await this.getList()
        }
        //若未登录，则跳出登录框
        else { this.dialogVisibles = true }
      },
      //剩余监控个数
      async monitorRemaining() {
        await this.$axios
          .get("/product/servicemanage/getMonitorTimes").then(({data}) => {
            if (data.code == 0) {
              //总次数
              this.totalTimes = data.data.totalTimes
              //使用次数
              this.usageTimes = data.data.usageTimes
              //剩余次数
              this.remainTimes = data.data.remainTimes
            }
          })
      },
      //表格过滤变化触发
      filterChange() {
        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()
        let getSortColumns = this.$refs.xGrid.getSortColumns()
        this.filterChangeObj = {
          agentName: '',
          agentNo: '',
          agentAddress: '',
          orderField: '',
          orderType: ''
        }
        this.current = 1
        if (getSortColumns.length > 0) {
          this.filterChangeObj.orderField = 'app_count_lastyear'
          getSortColumns[0].order == 'desc' ? this.filterChangeObj.orderType = 1 : this.filterChangeObj.orderType = 2;
        } else {
          this.filterChangeObj.orderType = ''
          this.filterChangeObj.orderField = ''
        }
        if(getCheckedFilters.length > 0){
          getCheckedFilters.map(item => {
            switch (item.property) {
              //申请人名称
              case "agentName":
                this.filterChangeObj.agentName = item.datas[0];
                break;
              case "agentCode":
                this.filterChangeObj.agentNo = item.datas[0];
                break;
              case "address":
                this.filterChangeObj.agentAddress = item.datas[0];
                break;
            }
          })
        }else {
          this.filterChangeObj.agentName = ''
          this.filterChangeObj.agentNo = '';
          this.filterChangeObj.agentAddress = '';
        }
        this.getAreData()
      },

      //给图片添加地址
      returnUrl(url) {
        if (url.startsWith('/group')) return "https://tmimage.lanternfish.cn"+url
        else return url
      },

      //查看监控结果详情
      viewMonitoring(id, cnApplicant, appno, imgUrl, cusCaseNo, country, row) {
        localStorage.setItem("imgUrl", imgUrl)
        localStorage.setItem("selectedcountry", country.split(";"))
        this.$router.push({
          path: "/monitoringResult",
          query: {
            step: 2,
            md: "t1_11",
            id: id,
            ca: cnApplicant,
            no: appno,
            youRef: cusCaseNo,
            country,
            content: row.isImage == "图片" ? row.image : row.tmcn
          }
        })
      },

      //终止监控
      zhongzhi(item){
        this.$axios.post("/trademark/monitoring/stopMonitoringTask", {monitoringId: item.id})
          .then(({data}) => {
            if (data.code != 0) { this.$message("终止商标监控任务失败") }
            else { this.$message("终止商标监控任务成功") }
            //获取列表数据
            this.getList()
          })
          .catch(error => { console.log(error) })
      },
      //启用监控
      enableEven(value) {
        localStorage.setItem('monitoringIdSet',JSON.stringify(value))
        this.$axios
          .post("/trademark/monitoring/startMonitoringTask", {monitoringIdSet: value})
          .then(({data}) => {
            if (data.code == 0) {
              //终止商标监控任务成功
              this.getList()
              this.$message({showClose: true, message: '启用商标监控任务成功'})
            }
            else if (data.code == 900) {
              this.$confirm(data.data.paymentMessage, '提示', {confirmButtonText: '立即支付', cancelButtonText: '暂不支付', type: 'warning'})
                .then(() => { this.createOrder(data.data,3)} )
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : `本次启用失败。剩余个数还是${this.remainTimes}个`
                  })
                })
            }
            else if (data.code == 1) {
              this.$message({
                showClose: true,
                message: '启用商标监控任务失败'
              })
            }
          })
      },

      handleSizeChange(pageSize) {
        this.size = pageSize
        //获取列表数据
        this.getAreData()
      },
      //表格页码变化触发
      handleCurrentChange(currentPage) {
        this.current = currentPage
        //获取列表数据
        this.getAreData()
      },

      //新增或修改商标监控国家变化触发
      SelectCountryFn(val) {
        let arr = []
        if (val.length == 0) {
          this.$refs.tree[0].setCheckedKeys([])
          this.containCN = false
        }
        else {
          this.allCountry.map(item => {
            val.map(items => {
              if (item.city == items) {
                arr.push([item.code])
              }
              return
            })
            return
          })
          this.$refs.tree[0].setCheckedKeys(arr)

          let CN = false
          val.map(item=>{
            if (item == '中国大陆'){
              CN = true
            }
          })
          this.containCN = CN
          if (this.containCN == false){
            this.addOrEditData.revokeThreeFlag = undefined
            this.addOrEditData.renewalFlag = undefined
            this.addOrEditData.trademarkApplyNo = undefined
            this.addOrEditData.trademarkOwnFlag = undefined
            this.addOrEditData.renewalNiceCode = undefined
          }
        }
      },

      //新增或修改商标监控国家确定触发
      confirm() {
        this.addOrEditData.countrySet = []

        //this.$refs.tree[0].getCheckedKeys() 内存储树形结构选中项
        // 0 1 2 3 4 5 6 7表示 全选 亚洲 欧洲 北美洲 南美洲 非洲 大洋洲 世界知识产权组织
        // 其余则为国家简写 如 中国大陆->CN

        if (this.$refs.tree[0].getCheckedKeys().includes(0)) {
          //若存在 0 则表示全选，将所有国家/地区插入this.addOrEditData.countrySet
          this.allCountry.map(item => {
            this.addOrEditData.countrySet.push(item.city)
            return
          })
        }

        else {
          this.allCountry.map(item => {
            this.$refs.tree[0].getCheckedKeys().map(items => {
              if (item.code == items) {
                this.addOrEditData.countrySet.push(item.city)
                return
              }
            })
            return
          })
        }

        if (this.$refs.tree[0].getCheckedKeys().includes("CN")){
          this.containCN = true
        }
        else {
          this.containCN = false
        }

        // this.$refs.fuzzySearch.blur()
      },
      //新增监控选择国家过滤方法
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },

      //新增监控图片上传前的钩子
      beforeAvatarUpload(file) {
        const isLt5M = file.size < 1024 * 1024 * 5
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          return false
        }
        return true
      },
      //新增监控上传图片成功
      uploadFn(response) {
        if (response.code == 0){
          this.addOrEditData.iconImageUrl = response.data.url
        }
        else this.$message('图片上传失败')
      },
      //新增商标监控删除图片
      deleteImg() {
        this.addOrEditData.iconImageUrl = ""
        this.addOrEditData.sourctType = 0
      },

      //新增商标监控打开类别选择dialog
      open() { this.niceVisible = true },
      categoryType(value){
        if (value) this.monitorCategoryOrRenewalCategory = 0
        else this.monitorCategoryOrRenewalCategory = 1
      },

      //新增商标监控保存接口
      save() {
        localStorage.setItem('flag',1)
        if (this.addOrEditData.countrySet.length == 0){
          this.$message("请选择监控国家")
          return
        }
        if (this.addOrEdit == 1 && this.addOrEditData.countrySet.length > this.beforeEditCountryListNum){
          this.$message("您选择国家的个数已超过原监控国家的个数")
          return
        }

        if(this.addOrEditData.monitorType == 1){
          if (this.addOrEditData.niceSet == []){
            this.$message("请选择监控类别")
            return
          }

          if (this.addOrEditData.trademarkName == "" && this.addOrEditData.iconImageUrl == ""){
            this.$message("请输入商标名称或上传商标图片")
            return
          }
        }

        if(this.addOrEditData.monitorType == 2){
          if (!this.addOrEditData.applyPersonName){
            this.$message("请输入申请人名称")
            return
          }
        }

        if (this.containCN && this.addOrEditData.revokeThreeFlag != undefined && this.addOrEditData.revokeThreeFlag == true){
          if (this.addOrEditData.trademarkApplyNo == undefined || this.addOrEditData.trademarkApplyNo == ''){
            this.$message("请输入商标申请号")
            return
          }
          if (this.addOrEditData.trademarkOwnFlag == true ? false : this.addOrEditData.trademarkOwnFlag == false ? false : true){
            this.$message("请选择是否为商标专有人")
            return
          }
        }

        if (this.containCN && this.addOrEditData.renewalFlag != undefined && this.addOrEditData.renewalFlag == true){
          if (this.addOrEditData.trademarkApplyNo == undefined || this.addOrEditData.trademarkApplyNo == ''){
            this.$message("请输入商标申请号")
            return
          }
          if (this.addOrEditData.renewalNiceCode == undefined || this.addOrEditData.renewalNiceCode == ''){
            this.$message("请选择商标续展所需的商标类别")
            return
          }
        }

        if (!this.containCN){
          this.addOrEditData.revokeThreeFlag = undefined
          this.addOrEditData.renewalFlag = undefined
          this.addOrEditData.trademarkApplyNo = undefined
          this.addOrEditData.trademarkOwnFlag = undefined
          this.addOrEditData.renewalNiceCode = undefined
        }

        this.isDisabled = true
        //若存在商标名称，则创建文字图像并创建监控订单
        if (this.addOrEditData.trademarkName != "" && this.addOrEditData.trademarkName != undefined) {
          let jishu = this.getBLen(this.addOrEditData.trademarkName) * 0.6
          this.$axios.post("/trademark/trademark/generateUrl", {
            font: this.addOrEditData.trademarkName,
            fontpx: 1000 / jishu
          })
            .then(({data}) => {
              if (data.code == 0) {
                this.addOrEditData.wordImageUrl = data.data

                if (this.addOrEditData.id == ""){
                  localStorage.setItem('addOrEditData',JSON.stringify(this.addOrEditData))
                  this.createdOrderFn()
                }
                else {
                  this.updateOrderFn()
                }
              }
            })
            .catch(error => {
              this.isDisabled = false
            })
        }

        //若不存在商标名称，则直接创建监控订单
        else {
          if (this.addOrEditData.id == ""){
            localStorage.setItem('addOrEditData',JSON.stringify(this.addOrEditData))
            this.createdOrderFn()
          }
          else {
            this.updateOrderFn()
          }
        }
      },
      getBLen(str) {
        var len = 0
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94 || str.charCodeAt(i) == 87) {
            len += 2
          } else if ((str.charCodeAt(i) >= 65 && str.charCodeAt(i) <= 90)) {
            len += 1.3
          } else {
            len++
          }
        }
        if (len == 1) len = 1.8
        if (len == 1.3) len = 2
        return len
      },
      //创建监控订单
      createdOrderFn() {
        let doAddOrEditData = {}
        let sourceType = this.addOrEditData.iconImageUrl == '' ? 0 :this.addOrEditData.iconImageUrl.startsWith('/group') ? 1 : 0

        doAddOrEditData.countrySet = []

        if(this.addOrEditData.monitorType == 1){
          doAddOrEditData.trademarkName = this.addOrEditData.trademarkName
          doAddOrEditData.wordImageUrl = this.addOrEditData.wordImageUrl
          doAddOrEditData.iconImageUrl = this.addOrEditData.iconImageUrl
          doAddOrEditData.remarks = this.addOrEditData.remarks
          doAddOrEditData.niceSet = this.addOrEditData.niceSet
        }else {
          doAddOrEditData.applyPersonName = this.addOrEditData.applyPersonName
        }

        doAddOrEditData.monitorType = this.addOrEditData.monitorType
        doAddOrEditData.sourceType = sourceType
        doAddOrEditData.revokeThreeFlag = this.addOrEditData.revokeThreeFlag
        doAddOrEditData.renewalFlag = this.addOrEditData.renewalFlag
        doAddOrEditData.trademarkApplyNo = this.addOrEditData.trademarkApplyNo
        doAddOrEditData.trademarkOwnFlag = this.addOrEditData.trademarkOwnFlag
        doAddOrEditData.renewalNiceCode = this.addOrEditData.renewalNiceCode

        this.addOrEditData.countrySet.forEach(item=>{
          doAddOrEditData.countrySet.push(this.searchCountryCodeByName(item))
        })
        if(this.addOrEditData.monitorType == 1){
          this.$axios.post("/trademark/monitoring/createMonitoringImageOrWord", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控添加成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.message)
            })
        }else {
          this.$axios.post("/trademark/monitoring/createMonitoringCompetitor", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控添加成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.message)
            })
        }

      },
      //修改监控订单
      updateOrderFn(){
        let doAddOrEditData = {}

        doAddOrEditData.monitoringId = this.addOrEditData.id
        doAddOrEditData.countrySet = []
        if(this.addOrEditData.monitorType == 1){
          doAddOrEditData.trademarkName = this.addOrEditData.trademarkName
          doAddOrEditData.wordImageUrl = this.addOrEditData.wordImageUrl
          doAddOrEditData.iconImageUrl = this.addOrEditData.iconImageUrl
          doAddOrEditData.remarks = this.addOrEditData.remarks
          doAddOrEditData.niceSet = this.addOrEditData.niceSet
        }else {
          doAddOrEditData.applyPersonName = this.addOrEditData.applyPersonName
        }
        doAddOrEditData.monitorType = this.addOrEditData.monitorType
        doAddOrEditData.sourceType = this.addOrEditData.sourceType

        this.addOrEditData.countrySet.forEach(item=>{
          doAddOrEditData.countrySet.push(this.searchCountryCodeByName(item))
        })

        if(this.addOrEditData.monitorType == 1){
          this.$axios.post("/trademark/monitoring/updateMonitoringImageOrWord", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控修改成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.message)
            })
        }else {
          this.$axios.post("/trademark/monitoring/updateMonitoringCompetitor", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控修改成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.data.msg)
            })
        }
      },
      //根据国家名称查询国家Code
      searchCountryCodeByName(countryName){
        let s = ""
        this.allCountry.map(item=>{
          if (item.city == countryName){
            s = item.code
          }
        })
        return s
      },

      close() {
        this.niceVisible = false
        this.monitor = true
      },
      classifyType(data) {
        if (this.monitorCategoryOrRenewalCategory == 0){
          this.addOrEditData.niceSet = []
          data.forEach(item=>{
            this.addOrEditData.niceSet.push(item.no)
          })
        }
        else {
          if (data.length > 1){
            this.$message("此处仅可以选择一个类别")
            return
          }
          else {
            this.addOrEditData.renewalNiceCode = data[0].no
          }
        }
      },

      // 登录弹层隐藏
      loginDialog(data) { this.dialogVisibles = data },
      dialogCloseBool(data) { this.dialogClose = data },
      closeEvent(done) {
        this.dialogClose = true
        done()
      },
    },

    computed: {
      //所有国家
      allCountry(){ return this.$t(`PQ.cityOptions`).concat(this.$t(`PQ.city`), this.$t(`PQ.cityOptionsd`), this.$t(`PQ.cityOptionsds`), this.$t(`PQ.cityOpts`), this.$t(`PQ.cityds`), this.$t(`PQ.propertyOrganization`))},
    }
  }
</script>

<style lang="less">
  .institution {
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }

    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .row--hover {
      background: #EBF0FA !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
      td:nth-of-type(1) {
        position: relative;
        &:before {
          width: 2px;
          height: 64px;
          background: #2264E5;
          border-radius: 0px 0px 0px 0px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .trademarkStatus {
        width: 52px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff!important;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #4F5AED;
      }
    }

    .vxe-grid--pager-wrapper {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(244,247,252,0.75);
      border-radius: 8px 8px 8px 8px;
      margin-top: 10px;
      text-align: right;
      ul {
        background: rgba(244,247,252,0.75);
      }
      button {
        background: rgba(244,247,252,0.75);
      }
      .el-input__inner {
        background: rgba(244,247,252,0.75);
        border: none;
      }
    }
    .vxe-cell--checkbox {
      .vxe-checkbox--icon {
        font-size: 16px;
        width: 17px;
        height: 16px;
        color: #ffffff;
        background: #ffffff;
        border-radius: 4px; /* 圆角 */
        border: none!important;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) ; /* 阴影效果 */
      }
    }
    /* 自定义 VXE-Table 复选框的阴影效果 */
    .vxe-cell--checkbox.is--checked {
      .vxe-checkbox--icon {
        /*border: 1px solid #dcdfe6!important; !* 边框颜色 *!*/
        &.vxe-icon-checkbox-checked {
          width: 18px;
          height: 17px;
          box-shadow: none !important; /* 阴影效果 */
          color: #2264E5!important;
        }
      }
    }

    .vxe-body--row {
      height: 64px;
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
    }



    .vxe-table--header {
      height: 70px;
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    .color-row1-1{
      background: #F5F7FA;
    }

    tbody {
      .vxe-cell {
        font-weight: 500;
        font-size: 12px;
        color: #464F60;
        .trademarkImageUrl {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
          border-radius: 8px!important;
          padding: 8px 31px!important;
          box-sizing: border-box;
        }

        .trademarkStatus {
          width: 52px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #EAECFF;
          border-radius: 20px;
          font-weight: 400;
          font-size: 12px;
          color: #4F5AED;
        }
      }
    }


    .vxe_grid_header {
      font-weight: 500;
      font-size: 14px;
      color: #464F60;
      border-radius: 5px 5px 0px 0px !important;
      overflow: hidden !important;
      background: rgba(244,247,252,0.75) !important;
    }



    .vxe-body--row {
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
      .operation {
        display: flex;
        justify-content: space-around;
        div {
          width: 78px;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:nth-of-type(1) {
            background: #F2F6FF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #3B5789;
            font-weight: 400;
            font-size: 12px;
            color: #2555A2;
          }
          &:nth-of-type(2) {
            background: #2264E5;
            border-radius: 15px 15px 15px 15px;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
          }
        }
      }
    }

    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
  }
</style>

<style scoped lang="less">
  .institution {
    .monitoring_table {
      padding: 27px 19px;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
      border-radius: 15px 15px 15px 15px;
      /deep/.el-button--primary.is-plain {
        color: #ffffff;
      }
      .type {
        margin-bottom: 31px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .type_switch {
          display: flex;
          width: 143px;
          border-radius: 8px 8px 8px 8px;
          height: 36px;
          background: #E8EDF6;
          li {
            width: 65px;
            height: 36px;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;
            color: #555D66;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              width: 78px;
              font-size: 14px;
              color: #FFFFFF;
              border-radius: 8px 8px 8px 8px;
              background: #323B71;
            }
          }
        }
        .type_select {
          .el-select {
            width: 137px;
            height: 40px;
            background: #F7F7F8;
            border-radius: 15px;
            overflow: hidden;
            border: 1px solid #E2E8F0;
            /deep/.el-input {
              font-weight: 600;
              font-size: 14px;
              color: #000000;
              border-radius: 15px;
              border: none!important;
              background: #F7F7F8;
              .el-input__inner {
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                border-radius: 15px;
                border: none!important;
                background: #F7F7F8;
              }
              input {

              }
            }
          }
        }
      }
    }
  }
</style>
